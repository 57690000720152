<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <view-table />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import viewTable from '../components/tables/Views/viewTable.vue';
export default {
  components: { viewTable },
  name: 'Views',
};
</script>
