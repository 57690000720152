const HEAD_TABLE = [
  {
    text: "Name",
    align: "start",
    class: "sticky-header grey lighten-3 font-weight-bold subtitle-1 ",
    value: "name",
    sortable: true
  },
  {
    text: "Description",
    align: "start",
    class: "sticky-header grey lighten-3 font-weight-bold subtitle-1 ",
    value: "description",
    sortable: true
  },
  {
    text: "Created",
    align: "start",
    class: "sticky-header grey lighten-3 font-weight-bold subtitle-1 ",
    sortable: true,
    value: "Created",
  },
  {
    text: "LastUpdated",
    align: "start",
    class: "sticky-header grey lighten-3 font-weight-bold subtitle-1 ",
    sortable: true,
    value: "LastUpdated",
  },

  {
    text: "", align: "end", sortable: false,
    class: "sticky-header grey lighten-3 font-weight-bold subtitle-1 ",
    value: "views"
  }
];

export { HEAD_TABLE };