<template>
  <v-dialog width="60%"
    v-if="stuff"
    scrollable
    @click:outside="handleClose"
    transition="dialog-bottom-transition"
    v-model="value"
  >
    <v-card height="100%">
      <div class="mx-3 mb-3">
        <v-card-actions>
          <v-card-title>Editar View</v-card-title>
          <v-spacer></v-spacer>
          <v-btn text fab color="red" @click="handleClose()">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="putAtualiza(stuff), getViews">
            Aplicar
          </v-btn>
        </v-card-actions>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="name"
              outlined
              v-model="stuff.name"
            ></v-text-field>
            <!-- <v-text-field
              label="icon"
              placeholder="mdi-icon"
              outlined
              v-model="stuff.icon"
            ></v-text-field> -->
            <v-text-field
              label="description"
              outlined
              v-model="stuff.description"
            ></v-text-field>
            <!-- <v-text-field
              label="schemaName"
              outlined
              v-model="stuff.schemaName"
            ></v-text-field> -->
            <v-textarea
              label="query"
              line-height="6"
              outlined
              v-model="stuff.query"
            ></v-textarea>

            <div>
              <span class="ml-1 font-weight-bold">Parameters</span>
              <span>
                <v-btn
                  class="ma-2"
                  @click="addParams('stuffEdit')"
                  text
                  icon
                  color="green lighten-2"
                >
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
              </span>
            </div>
            <div
              class="form-group"
              v-for="(params, k) in stuff.parameters"
              :key="k"
            >
              <v-row class="mx-1">
                <v-text-field
                  label="name"
                  class="form-control mr-2"
                  outlined
                  v-model="params.name"
                ></v-text-field>
                <v-text-field
                  label="dataType"
                  class="form-control"
                  outlined
                  v-model="params.dataType"
                ></v-text-field>
                <v-text-field
                  label="value"
                  class="form-control"
                  outlined
                  v-model="params.value"
                ></v-text-field>
                <span>
                  <v-btn
                    class="ma-2"
                    @click="removeParams('stuffEdit', k)"
                    text
                    icon
                    color="red lighten-2"
                  >
                    <v-icon>mdi-minus-circle-outline</v-icon>
                  </v-btn>
                </span>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import viewsMainMixin from '../../../../mixins/tables/Views/viewsMainMixin';
export default {
  mixins: [viewsMainMixin],
  props: ['value', 'stuff'],
  data: function () {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style>
</style>